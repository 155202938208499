import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import ContactBox from '@components/molecules/ContactBox';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ListCards from '@components/organisms/ListCards';
import ItemBullet from '@components/molecules/items/ItemBullet';
// import ListRelatedProjects from '@components/organisms/ListRelatedProjects';

// assets
import imgStrategy from '@assets/images/ico-strategy.svg';
import imgAudit from '@assets/images/ico-strategy-audit.svg';
import imgBusinessPositioning from '@assets/images/ico-strategy-business-positioning.svg';
import imgIdeation from '@assets/images/ico-strategy-ideation.svg';
import imgResearch from '@assets/images/ico-strategy-research.svg';
import imgStrategicForesight from '@assets/images/ico-strategy-strategic-foresight.svg';
import imgIcoStrategy from '@assets/images/ico-strategy-strategy.svg';
import iconBullet1 from '@assets/images/bullet_1.svg';
import iconBullet2 from '@assets/images/bullet_2.svg';
import iconBullet3 from '@assets/images/bullet_3.svg';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-strategy';

const footerRelatedLinks = [
	{ title: 'Branding', url: '/fr/agence-branding-suisse/' },
	{ title: 'Solutions web', url: '/fr/agence-web-suisse/' },
];

const stepsList = [
	{
		image: iconBullet1,
		title: 'Présentez-nous votre problématique',
		text: [
			'Toute belle aventure débute par une rencontre. Discutons de votre challenge ou objectif, permettant de vous proposer un processus sur mesure.',
			<>
				<b>Partagez-nous votre besoin :</b>
				<br />
				Positionnement
				<br />
				Augmentation d'impact
				<br />
				Stratégie de communication
			</>,
		],
	},
	{
		image: iconBullet2,
		title: 'Nous créons une stratégie sur mesure',
		text: [
			"Identifions les opportunités et les possibilités pour construire un plan stratégique clair que nous accompagnerons jusqu'à son succès.",
			<>
				Consulting & analyse
				<br />
				Workshops stratégiques
				<br />
				Plan en étapes
			</>,
		],
	},
	{
		image: iconBullet3,
		title: 'Atteignez votre objectif',
		text: [
			"Nous accompagnons la stratégie jusqu'à son succès par l'intermédiaire de nos compétences ou en proposant des partenaires externes.",
			"Nous analysons l'impact de sa performance.",
		],
	},
];

const cardsList = [
	{
		image: imgBusinessPositioning,
		title: 'Positionnement',
		alt: "Stratégie de positionnement d'entreprise",
		text:
			'Positionner correctement votre activité pour en accroître le développement et les opportunités.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgIcoStrategy,
		title: 'Stratégie',
		alt: 'Agence stratégie digitale suisse - Lausanne',
		text:
			'Concevoir une stratégie performante facilitant l’acquisition et le gain en visibilité.',
	},
	{
		image: imgResearch,
		title: 'Recherche',
		alt: 'Recherche utilisateur & interview',
		text:
			'Comprendre les réels besoins de votre audience pour concevoir une solution performante.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgIdeation,
		title: 'Idéation',
		alt: 'Ideation stratégique',
		text:
			'Imaginer des nouvelles solutions et des idées pour consolider votre avenir.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgStrategicForesight,
		title: 'Strategic foresight',
		alt: 'Strategic Foresight',
		text:
			'Faciliter les changements et les solutions en adéquation avec les nouvelles tendances de votre marché.',
	},
	{
		image: imgAudit,
		title: 'Audit',
		alt: 'Audit et analyse stratégique',
		text:
			'Auditer votre marque ou votre service digital pour en identifier les actions précises d’amélioration.',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const StrategyPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				projectImages: allFile(
					filter: {
						relativePath: { in: ["projects/MJF-Swipe-SQUARE.jpg"] }
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 620) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`
	);

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey]['edges'].find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	// const caseStudiesList = [
	// 	{
	// 		image: {
	// 			...data.getImageAttributes(
	// 				'projectImages',
	// 				'projects/MJF-Swipe-SQUARE.jpg'
	// 			),
	// 			alt: '',
	// 		},
	// 		uptitle: 'Montreux Jazz Festival',
	// 		question:
	// 			'Comment faciliter la compréhension et l’appropriation du programme du Montreux Jazz Festival?',
	// 		title: 'MJF Swipe',
	// 		url: '/todo',
	// 	},
	// ];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/en/digital-strategy-agency/', lang: 'en' }]}
			seo={{
				description: `Agence de stratégie digitale, stratégie de marque et de communication, stratégie UX`,
			}}
			title="Agence stratégie digitale suisse – Lausanne, Genève"
		>
			<HeroPage
				image={imgStrategy}
				title={'Stratégie'}
				subtitle={'Agence stratégie digitale suisse – Lausanne, Genève'}
				alt={'Agence stratégie digitale suisse – Lausanne, Genève'}
			/>

			<section
				className="grid text-content text-center"
				aria-label="Introduction"
				data-animation-page
			>
				<h2 className="col-big">
					Augmenter votre impact <br /> Accélérer votre développement
				</h2>
				<p className="p-highlight col-big">
					Stratégie et Innovation business
				</p>
			</section>

			<section className="grid grid-content" aria-label="Description">
				<div className="box text-content" data-animation-page>
					<h2>
						Initier des changements qui transforment votre activité
					</h2>
					<h3>Vous désirez:</h3>
					<ul className="list-two-columns list-default list-brand">
						<li>
							<p>
								Obtenir des solutions innovantes et tangibles à
								vos problématiques ?
							</p>
						</li>
						<li>
							<p>
								Bénéficier d’une vision d’ensemble et gagner en
								recul ?
							</p>
						</li>
						<li>
							<p>
								Arrêter de présupposer mais construire sur des
								données ?
							</p>
						</li>
						<li>
							<p>
								Disposer d’une stratégie solide sur laquelle
								construire votre futur ?
							</p>
						</li>
					</ul>
					<ButtonPrimary
						className="element-left"
						href="#message"
						text="Discutons de votre challenge"
					/>
				</div>
			</section>

			{/* <section
				className="grid grid-content text-content text-center"
				aria-label="Projects"
				data-animation-page
			>
				<p className="uptitle">
					Case Studies
				</p>
				<h2>Un exemple concret</h2>
				<ListRelatedProjects
					className={['col-big']}
					type="casestudy"
					modifiers="casestudy"
					projectsList={caseStudiesList}
				/>
			</section> */}

			<section aria-label="Process">
				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">
							Les trois étapes pour une stratégie réussi
						</p>
						<h2>Comment procéder&nbsp;?</h2>
						<div className="list-three-columns">
							{stepsList.map((step, index) => (
								<ItemBullet
									key={`step-${index}`}
									image={step.image}
									title={step.title}
									text={step.text}
								/>
							))}
						</div>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Contact">
				<ContactBox
					title={
						<>
							Parlez-nous
							<br />
							de votre projet
						</>
					}
					link={{ title: 'Envoyer un message', url: '#message' }}
					text={
						<>
							Ou par téléphone:{' '}
							<Link href="tel:+41216521818">
								+41 21 652 18 18
							</Link>
						</>
					}
				/>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Services"
				data-animation-page
			>
				<p className="uptitle">Domaines D'Expertise</p>
				<h2>Nos services de stratégie</h2>
				<ListCards modifiers="zigzag" cardsList={cardsList} />
			</section>

			<section className="grid" aria-label="Prendre contact" id="message">
				<FormMessage
					title={
						<>
							Comment pouvons-nous
							<br />
							vous aider dans votre projet&nbsp;?
						</>
					}
					label="Message"
					text="Pour nous contacter par email"
					contact="hello@superhuit.ch"
				/>
			</section>
		</Layout>
	);
};

export default StrategyPage;
